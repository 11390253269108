.charDhamVideo {
  height: 550px;
}

#chardham h1 {
  font-size: 34px;
}

#chardham .chardhamWrapper {
  margin: 60px 40px;
}

@media screen and (max-width: 768px) {
  .charDhamVideo {
    height: 380px;
  }

  #chardham h1 {
    font-size: 28px;
  }

  #chardham .chardhamWrapper {
    margin: 40px 20px;
  }
}

@media screen and (max-width: 468px) {
  .charDhamVideo {
    height: 280px;
  }

  #chardham h1 {
    font-size: 24px;
  }

  #chardham .chardhamWrapper {
    margin: 40px 10px 10px 10px;
  }
}
