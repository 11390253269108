.homeWrapper {
  display: flex;
  min-height: 100vh;
  padding-top: 60px;
  width: 100%;
}
.cardHolder {
  width: 70%;
  padding-left: 90px;
  padding-top: 65px;
}
p {
  font-family: "Montserrat";
  font-size: 18px;
}

.cardHolder .titleOne {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #e2b779;
}
.cardHolder .titleTwo {
  margin-top: 12px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  width: 600px;
}

.cardHolder .titleThree {
  margin-top: 16px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #767a97;
}
.cardHolder .titleThree b {
  font-weight: 700;
  color: black;
}

.cardHolder .cardsWrapper {
  display: flex;
  margin-top: 32px;
}

.helitaxii_card {
  position: relative;
  width: 300px;
  padding: 32px 20px 24px;
  border-radius: 16px;
  background: #e8ecf4;
  margin-right: 8px;
  display: flex;
  flex-direction: column;
}

.helitaxii_card .card_badge {
  position: absolute;
  height: 32px;
  top: -16px;
  display: flex;
  align-items: center;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  padding: 0 16px;
  background: #e2b779;
  border-radius: 30px;
  color: white;
}

.helitaxii_card .card_subTitle {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}

.helitaxii_card .card_title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
}

.helitaxii_card .card_passengers {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #29305c;
  margin-bottom: 5px;
}

.helitaxii_card .card_price {
  margin-top: auto;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #29305c;
}

.helitaxii_card .card_price_type {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #767a97;
  margin-bottom: 10px;
}

.helitaxii_card .card_description {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: green;
  margin-bottom: 10px;
}

.buttonWrapper button {
  width: 100%;
  background: #194695;
  border: 1px solid #194695;
  box-sizing: border-box;
  border-radius: 48px;
  height: 48px;
  color: white;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.buttonWrapper button svg {
  margin-right: 12px;
}

.buttonWrapper button .phoneNumber {
  margin-left: 12px;
}
.imageHolder {
  overflow: hidden;
  width: 70%;
  background-image: url("/public/assets/trivandrum-bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.in-association-section {
  margin-top: 30px;
}

.in-association-section img {
  width: 100px;
  margin-right: 10px;
}

.in-association-section img:last-child {
  width: 200px;
  margin-right: 0px;
}

@media only screen and (max-width: 996px) {
  .homeWrapper {
    flex-direction: column-reverse;
    min-height: unset;
    height: auto;
    padding-bottom: 20px;
  }
  .cardHolder .titleTwo {
    width: auto;
  }
  .imageHolder {
    width: 100%;
    height: 200px;
    background-image: url("/public/assets/trivandrum-bg.jpg");
    background-position: center;
  }
  .cardHolder {
    width: 100%;
    padding: 24px;
  }

  .cardHolder .titleOne {
    font-weight: 400;
  }

  .cardHolder .titleTwo {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
  }

  .cardHolder .titleThree {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
  }

  .helitaxii_card .card_subTitle {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
  }

  .helitaxii_card .card_title {
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
  }
}

@media only screen and (max-width: 600px) {
  .cardHolder .cardsWrapper {
    display: block;
  }

  .cardsWrapper .helitaxii_card {
    width: 100%;
    margin-bottom: 30px;
  }

  .buttonWrapper button .phoneNumber {
    display: none;
  }

  .in-association-section img {
    width: 20%;
    margin-right: 2%;
  }

  .in-association-section img:last-child {
    width: 50%;
  }
}
