button.book-btn:hover {
  box-shadow: none !important;
  border: 2px solid black !important;
  background: white !important;
  color: black !important;
}

#joy-ride-page .banner.joride h1 {
  background: rgba(0, 0, 0, 0.5);
}
.path-wrapper {
  flex-direction: column;
  width: 50%;
  margin: 10px;
  padding: 10px 20px;
}

@media screen and (max-width: 996px) {
  .path-wrapper {
    width: unset;
    margin: 10px 20px;
  }

  .schedules-wrapper {
    flex-direction: column;
  }
  .form-wrapper > div {
    width: 100% !important;
  }
}

/* @media screen and (max-width: 996px) { */
.schedules-wrapper {
  padding: 0 !important;
  display: flex;
}
#joy-ride-page .banner.joride h1 {
  font-size: 30px !important;
}
.banner.joride {
  background-position-x: 25%;
}
.path-wrapper {
  flex-direction: column;
}
.path-wrapper .details img {
  width: 15% !important;
}
.path-wrapper .details img:last-child {
  width: 33% !important;
}
.path-wrapper .details {
  width: 100% !important;
  border-bottom: 1px solid #ccc;
  padding: 10px 0 !important;
}

.path-wrapper .seat-availability {
  width: 100% !important;
  padding: 10px 0 !important;
}
.available-seats,
.payable-amount {
  padding-left: 0px !important;
}
/* } */

.banner.joride {
  background-image: url("/public/assets/helicopter_4.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: 60%;
}
.lds-roller {
  display: inline-block;
  position: absolute;
  width: 64px;
  height: 64px;
  top: calc(50% - 32px);
  left: calc(50% - 32px);
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 32px 32px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #fff;
  margin: -3px 0 0 -3px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 50px;
  left: 50px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 54px;
  left: 45px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 57px;
  left: 39px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 58px;
  left: 32px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 57px;
  left: 25px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 54px;
  left: 19px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 50px;
  left: 14px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 45px;
  left: 10px;
}
input {
  cursor: pointer;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
