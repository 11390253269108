div.privacy-section{
  padding-top: 60px !important;
  padding: 80px 40px 40px 40px !important; 
}
div.privacy-section h2{
  margin: 30px 0 !important;
  font-size: 18px !important;
  font-weight: bold !important;
  text-transform: uppercase;
  text-decoration: underline;
  text-align: center;
}
div.privacy-section h3{
  margin: 30px 0 !important;
  font-size: 14px !important;
  font-weight: bold !important;
}
div.privacy-section ul.policy-points{
  list-style: circle;
  padding-left: 40px;
  height: auto !important;
  background-color: transparent !important;
  color: black;
}
div.privacy-section ul.policy-points li{
  background: transparent;
  color: black !important;
  line-height: 2 !important;
  margin-bottom: 10px;
}
div.privacy-section p{
  line-height: 2 !important;
}
