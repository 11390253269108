section.home-jumbotron {
  min-height: 100vh;
  background: url("../assets/helicopter.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 80px;
  /* border-bottom: 1px solid black; */
  position: relative;
}
section h3 {
  padding: 10px;
  text-align: center;
  font-size: 20px;
}

footer {
  padding: 10px;
}

footer ul li {
  display: inline-block;
  padding: 0 10px;
  border-right: 1px solid black;
}
footer ul li:last-child {
  border: none;
}
footer ul {
  text-align: center;
  background: transparent !important;
}
section .overlay-primary.right {
  display: inline-block;
  max-width: 700px;
  padding: 10px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 18px !important;
  background-blend-mode: multiply;
  background: rgba(0, 82, 153, 0.5);
  text-align: left;
}
section .overlay-primary.right p {
  font-size: 35px;
  line-height: 40px;
  text-transform: uppercase;
  color: white;
  font-weight: bold;
}
section .overlay-secondary.right {
  display: inline-block;
  max-width: 400px;
  padding: 10px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 18px !important;
  background-blend-mode: multiply;
  background: rgba(0, 82, 153, 0.75);
  margin-top: 20px;
}
section .overlay-secondary.right p {
  font-size: 30px;
  line-height: 40px;
  text-transform: uppercase;
  color: #f7ca18;
  font-weight: bold;
}
section .overlays {
  float: right;
  max-width: 700px;
  text-align: right;
}
.desc-primary {
  font-size: 18px !important;
  text-align: left;
  line-height: 18px !important;
}
.desc-secondary {
  text-align: left;
}
.section-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  min-height: 40px;
  z-index: 5;
  color: black;
  background: #f7ca18;
  padding: 10px;
  line-height: 20px;
}
.section-footer p {
  text-align: center;
  font-size: 18px;
  text-transform: uppercase;
}
footer ul {
  height: 18px !important;
}
footer ul li {
  line-height: 18px !important;
}

#airport-shuttle h3 {
  font-size: 40px !important;
  margin-top: 20px;
  font-family: "Roboto", sans-serif !important;
  color: white;
  text-transform: uppercase;
}
.bookings-wrapper {
  max-width: 700px;
  margin: auto;
  margin-top: 100px;
  /* margin-right: 40px; */
  padding: 40px;
  background: rgba(0, 0, 0, 0.7);
}
.bookings-wrapper p {
  font-size: 26px !important;
  color: white;
  margin-bottom: 20px;
  text-align: center;
  font-family: "Roboto", sans-serif !important;
}
.bookings-wrapper p.description {
  font-size: 16px !important;
  line-height: 30px;
  color: white;
  margin-bottom: 20px;
  text-align: justify;
  font-family: "Roboto", sans-serif !important;
}
.bookings-wrapper a.store-links {
  padding: 10px 20px;
  display: inline-block;
  margin-right: 20px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  background: rgba(0, 0, 0, 0.2);
  border-radius: 3px;
}
.bookings-wrapper a.store-links:hover {
  background: rgba(0, 0, 0, 0.5);
}
.bookings-wrapper img {
  height: 50px;
}
.bookings-wrapper .image-wrapper {
  text-align: center;
}
.bookings-wrapper .store-text {
  font-size: 20px !important;
}

#airport-shuttle {
  background-image: url("../assets/helicopter_1.jpg");
}

#emergency-services {
  background-image: url("../assets/helicopter_2.jpg");
  background-color: rgba(0, 0, 0, 0.5);
  background-blend-mode: multiply;
}
#emergency-services h3 {
  font-size: 40px !important;
  margin-top: 20px;
  font-family: "Roboto", sans-serif !important;
  color: white;
  text-transform: uppercase;
}
.emergency-wrapper {
  max-width: 900px;
  margin: auto;
  margin-top: 100px;
  padding: 40px;
  background: rgba(0, 0, 0, 0.7);
}

#joy-rides h3 {
  font-size: 40px !important;
  margin-top: 20px;
  font-family: "Roboto", sans-serif !important;
  color: white;
  text-transform: uppercase;
}

#joy-rides {
  background-image: url("../assets/helicopter_3.jpg");
  background-color: rgba(0, 0, 0, 0.6);
  background-blend-mode: multiply;
}
#private-charters h3 {
  font-size: 40px !important;
  margin-top: 20px;
  font-family: "Roboto", sans-serif !important;
  color: white;
  text-transform: uppercase;
}
#private-charters {
  line-height: 44px !important;
}
.bannerimage {
  width: 100%;
}
@media only screen and (max-width: 900px) {
  .bookings-wrapper {
    max-width: 700px;
    float: none;
    margin-top: 5px;
    margin-right: 0px;
  }
  #airport-shuttle h3,
  #private-charters h3,
  #emergency-services h3,
  #joy-rides h3 {
    font-size: 30px !important;
  }
}

@media only screen and (max-width: 600px) {
  .ad-message-wrapper h1 {
    font-size: 18px;
  }

  .ad-message-wrapper h2 {
    font-size: 16px !important;
  }
}

@media only screen and (max-width: 320px) {
  .ad-banner {
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin-top: 0 !important;
    border-radius: 0 !important;
    width: 100% !important;
  }
}

@-webkit-keyframes tada {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  10%,
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }

  30%,
  50%,
  70%,
  90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }

  40%,
  60%,
  80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes tada {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  10%,
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }

  30%,
  50%,
  70%,
  90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }

  40%,
  60%,
  80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

.tada {
  -webkit-animation-name: tada;
  animation-name: tada;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animated.infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

@media (prefers-reduced-motion) {
  .animated {
    -webkit-animation: unset !important;
    animation: unset !important;
    -webkit-transition: none !important;
    transition: none !important;
  }
}
