.row {
  margin: 0 !important;
}
.joyrideItemContainerBooking {
  margin-top: 100px;
}

.joyrideItemContainerBooking h2 {
  font-size: xx-large;
  color: white;
}
.joyrideItemContainerBooking h4 {
  text-align: left;
  color: #005299;
  padding: 5px;
}
.joyrideItemContainerBooking h3 {
  padding: 5px;
  text-align: left;
}
.joyrideItemContainerBooking .title {
  margin-bottom: 40px;
}
.joyrideItemContainerBooking .ridedetails {
  display: flex;
  background: white;
  width: 100%;
  padding-top: 20px;
}
.joyrideItemContainerBooking .ridedetailsinfo {
  padding: 5px;
}
.joyrideItemContainerBooking .ridedetailsinfo label {
  padding-bottom: 10px;
  /* color: #f7ca18; */
}
.joyrideItemContainerBooking p {
  color: red;
  margin-top: 5px;
  margin-bottom: 5px;
}
.seat-button:focus {
  outline: none;
}
