.pure-menu {
  height: 50px;
  background: #005299;
}
ul.c-nav {
  background-color: #005299 !important;
  height: 60px !important;
}
.c-nav__item {
  height: 100% !important;
}
ul.c-nav li {
  color: white !important;
  /* height: 100% !important; */
  line-height: 60px !important;
}
.nav_item {
  color: white !important;
}
.c-nav {
  position: fixed !important;
}
li.home:hover {
  background-color: transparent !important;
}
.nav_item_home > li:not(:disabled):hover {
  background-color: transparent !important;
  color: #fff;
}

.logoimage {
  line-height: 1px !important;
  height: 100%;
  vertical-align: unset;
  cursor: pointer;
}

.collapse-menu-button {
  display: none;
}
.collapse-menu-button {
  line-height: 70px !important;
}
.collapse-menu.c-card {
  background: #005299;
  height: auto !important;
  margin: 0 !important;
  max-height: 500px !important;
}
.collapse-menu li {
  display: block !important;
}
.hidden {
  display: none !important;
}
.visible {
  display: block !important;
}
.visible-inline {
  display: inline-block !important;
}
.collapse-menu-button {
  display: none !important;
}

@media only screen and (max-width: 900px) {
  .collapse-menu-button {
    display: inline-block !important;
  }
  .large-device-menu {
    display: none;
  }
}

.announcement-banner {
  position: fixed;
  top: 60px; /* Assuming top-100 means 100px */
  left: 0;
  width: 100%;
  z-index: 1000;
  background-color: #f9d461; /* This is the color for bg-blue-500 in Tailwind's default palette */
  padding: 0.5rem;
  text-align: center;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  font-size: 16px;
}
